import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';

const CreateApiClientModal = ({ isOpen, onRequestClose, onCreate }) => {
    const initialFormData = {
        name: '',
        api_key: '',
    };

    const [formData, setFormData] = useState(initialFormData);
    const [isLoading, setIsLoading] = useState(false);

    const handleCreate = async () => {
        setIsLoading(true);
        await onCreate(formData.name, formData.api_key);
        setIsLoading(false);
        handleCancel();
    };

    const handleCancel = () => {
        setFormData(initialFormData);
        onRequestClose();
    };

    const isDisabled = !formData.name || !formData.api_key;

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="fixed inset-0 bg-black bg-opacity-30" onClick={handleCancel}></div>
            <div className="bg-white rounded-lg shadow-lg max-w-xl w-full p-6 relative z-10 max-h-screen overflow-y-auto">
                <FaTimes className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 cursor-pointer" onClick={handleCancel} size={12} />
                <h2 className="text-xl font-semibold mb-4">Create API Client</h2>
                <form onSubmit={(e) => e.preventDefault()}>
                    <div className="flex flex-col gap-4">
                        <div className="flex flex-col mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Client Name</label>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                required
                            />
                        </div>
                        <div className="flex flex-col mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">API Key</label>
                            <input
                                type="text"
                                name="api_key"
                                value={formData.api_key}
                                onChange={(e) => setFormData({ ...formData, api_key: e.target.value })}
                                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                required
                            />
                        </div>
                    </div>
                    <div className="flex justify-end mt-4">
                        <button
                            type="button"
                            onClick={handleCancel}
                            className="px-4 py-2 border rounded-md shadow-sm focus:outline-none bg-gray-500 text-white mr-2"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            onClick={handleCreate}
                            className={`px-4 py-2 border rounded-md shadow-sm focus:outline-none bg-blue-500 text-white ${isDisabled && 'opacity-80 cursor-not-allowed'}`}
                        >
                            {isLoading ? 'Creating...' : 'Create'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateApiClientModal;
