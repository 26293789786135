import { create } from 'zustand';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';

const useApiClientStore = create((set) => ({
    apiClient: null,
    apiClients: [],

    fetchApiClients: async () => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.get(`${process.env.REACT_APP_ADMIN_API}/admin/api-clients`, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            set({ apiClients: response.data.apiClients });
        } catch (error) {
            console.error('Failed to fetch API clients:', error);
        }
    },

    fetchApiClientById: async (id) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.get(`${process.env.REACT_APP_ADMIN_API}/admin/api-clients/${id}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            return response.data.apiClient;
        } catch (error) {
            console.error(`Failed to fetch API client with id ${id}:`, error);
            throw error;
        }
    },

    createApiClient: async (name, api_key) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/admin/api-clients`,
                { name, api_key },
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken.toString()}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            set((state) => ({ apiClients: [...state.apiClients, response.data.apiClient] }));
            return response.data.apiClient;
        } catch (error) {
            console.error('Failed to create API client:', error);
            throw error;
        }
    },

    updateApiClient: async (id, updatedData) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.patch(`${process.env.REACT_APP_ADMIN_API}/admin/api-clients/${id}`,
                updatedData,
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken.toString()}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            set((state) => ({
                apiClients: state.apiClients.map((client) =>
                    client.id === id ? response.data.apiClient : client
                ),
            }));
            return response.data.apiClient;
        } catch (error) {
            console.error('Failed to update API client:', error);
            throw new Error(error.response.data.message);
        }
    },

    deleteApiClient: async (id) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.delete(`${process.env.REACT_APP_ADMIN_API}/admin/api-clients/${id}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            set((state) => ({
                apiClients: state.apiClients.filter((client) => client.id !== id),
            }));
            return response.data.message;
        } catch (error) {
            console.error('Failed to delete API client:', error);
            throw new Error(error.response.data.message);
        }
    },
}));

export default useApiClientStore;
