// src/Login.js
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import DarkLogo from '../images/logotype.png';
import UserIcon from '../images/user-icon.png';
import { useNavigate } from 'react-router-dom';
import TextInput from './ui/TextInput';
import Button from './ui/Button';
import { GradientBorder } from './ui/GradientBorder';
import { getCurrentUser, signIn, confirmSignIn } from 'aws-amplify/auth';
import useUserStore from '../store/user-store';

function Login() {
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const setUser = useUserStore((state) => state.setUser);
  const navigate = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      const { nextStep } = await signIn({ username: email, password: password });

      switch (nextStep.signInStep) {
        case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
          await confirmSignIn({
            challengeResponse: password,
          });
          break;
        default:
          break;
      }
      console.log("User before");
      const user = await getCurrentUser();
      console.log("user", user);
      setUser(user);
      localStorage.setItem('user_id', user.username);
      localStorage.setItem('email', email);
      navigate('/reports');
    } catch (error) {
      toast.error('The login details are incorrect. Please try again.');
      console.error('Error signing in:', error.message);
    }
  };

  useEffect(() => {
    const checkSession = async () => {
      try {
        const localId = localStorage.getItem('user_id');
        if (localId) {
          console.log("inside condition")
          const user = await getCurrentUser();
          console.log("user", user);
          if (user.username === localId) {
            setUser(user);
            
            navigate('/reports');
          }
        }
      } catch (error) {
        console.error('Error checking session:', error);
      }
    };
    checkSession();
  }, [navigate, setUser]);

  return (
    <div className='w-full h-screen bg-[#FAFAFA] flex flex-col items-center sm:flex sm:justify-center font-SuisseIntl'>
      <div className='flex flex-col items-center mt-12'>
        <img width={'180px'} src={DarkLogo} alt='logo not found' />
        <p className='text-[#505050] font-SuisseIntlLight font-normal mt-[19.2px] text-[16px]'>
          Admin Portal
        </p>
      </div>
      <div className='w-[90vw] md:w-[440px] max-w-[440px] rounded-2xl card-shadow bg-white  shadow-sm p-[30px]  mt-10 text-center transition-all ease-in-out duration-150 flex flex-col justify-center items-center '>
        <GradientBorder>
          <div className="shadow-sm bg-white rounded-full px-4 py-4">
            <img width={'24px'} height={'24px'} src={UserIcon} alt='Login circle not found' />
          </div>
        </GradientBorder>
        <h1 className='mt-[20px] text-[24px] font-medium'>Login to the Admin Portal</h1>
        <hr className='h-[0.5px] bg-[#E5E7EC] mt-[20px] w-full' />
        <div className='w-full'>
          <form className='text-left' onSubmit={handleSignIn}>
            <div className='mt-[20px]'>
              <TextInput required={true} name={"email"} label={"Email"} placeholder={"Enter your email"} type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className='mt-[20px]'>
              <TextInput required={true} name={'password'} label={"Password"} placeholder={"Enter your password"} type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
            <div className='mt-[12px]'>
              <Link to='/reset-password' className='mt-7 text-[#525866] underline font-medium cursor-pointer'>Forgot password?</Link>
            </div>
            <div className='mt-[20px]'>
              <Button className='font-medium' variant='default' type='submit'>Login</Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
