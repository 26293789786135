import { useState } from 'react';
import { FaTimes } from 'react-icons/fa';

const DeleteModal = ({ isOpen, onClose, onDelete, data }) => {

    const [loading, setLoading] = useState(false);
    const dataName = data.dataName.charAt(0).toUpperCase() + data.dataName.slice(1);
    const name = data.name?.split('_').join(' ');

    const handleDelete = async () => {
        setLoading(true);
        await onDelete(data.id);
        setLoading(false);
    }

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="fixed inset-0 bg-black bg-opacity-30" onClick={onClose}></div>
            <div className="bg-white rounded-lg shadow-lg max-w-md w-full p-6 relative z-10">
                <FaTimes className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 cursor-pointer" onClick={onClose} size={12} />
                <h2 className="text-xl font-semibold mb-4">Delete {dataName}</h2>
                <p>Are you sure you want to delete {data.name ? <b>{name}</b> : <span>{'this '}<b>{data.dataName}</b></span>}?</p>
                <div className="flex justify-end mt-4">
                    <button
                        type="button"
                        onClick={onClose}
                        className="px-4 py-2 border rounded-md shadow-sm focus:outline-none bg-gray-500 text-white mr-2"
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        onClick={handleDelete}
                        className="px-4 py-2 border rounded-md shadow-sm focus:outline-none bg-red-500 text-white"
                        disabled = { loading }
                    >
                        { loading ? 'Deleting..' : 'Delete' }
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DeleteModal;
