import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { trimData } from '../../utils/helper_functions';

const EditReportModal = ({ isOpen, onRequestClose, report, onEdit }) => {
    const [formData, setFormData] = useState({
        report: '',
        error: '',
        patient_name: '',
        doctor_name: '',
        clinic_name: '',
        row_number: '',
        nova_transcription: '',
        whisper_transcription: '',
        audio_language: '',
        report_language: '',
        status: '',
        user_id: '',
        comment: '',
        confidence: '',
        report_fetched: false,
        uses_custom_template: false,
        template: '',
        retried_at: '',
        created_at: '',
        assembly_ai_transcription: '',
        rev_ai_transcription: '',
        meta_type: '',
    });

    const [initialData, setInitialData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);

    useEffect(() => {
        if (report) {
            const initialFormData = {
                report: report.report || '',
                error: report.error || '',
                patient_name: report.patient_name || '',
                doctor_name: report.doctor_name || '',
                clinic_name: report.clinic_name || '',
                row_number: report.row_number || '',
                nova_transcription: report.nova_transcription || '',
                whisper_transcription: report.whisper_transcription || '',
                audio_language: report.audio_language || '',
                report_language: report.report_language || '',
                status: report.status || '',
                user_id: report.user_id || '',
                comment: report.comment || '',
                confidence: report.confidence || '',
                report_fetched: report.report_fetched || false,
                uses_custom_template: report.uses_custom_template || false,
                template: report.template || '',
                retried_at: report.retried_at ? new Date(report.retried_at).toISOString().split('.')[0] : '',
                created_at: report.created_at ? new Date(report.created_at).toISOString().split('.')[0] : '',
                assembly_ai_transcription: report.assembly_ai_transcription || '',
                rev_ai_transcription: report.rev_ai_transcription || '',
                meta_type: report.meta_type || '',
            };
            setFormData(initialFormData);
            setInitialData(initialFormData);
        }
    }, [report]);

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [isOpen]);

    useEffect(() => {
        if (initialData) {
            const trimmedFormData = trimData(formData);
            const trimmedInitialData = trimData(initialData);

            const isFormDataChanged = JSON.stringify(trimmedFormData) !== JSON.stringify(trimmedInitialData);
            setIsSaveDisabled(!isFormDataChanged);
        }
    }, [formData, initialData]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setIsLoading(true);

            const formattedData = {
                ...formData,
                retried_at: formData.retried_at ? new Date(formData.retried_at).toISOString() : '',
                created_at: formData.created_at ? new Date(formData.created_at).toISOString() : '',
            };

            onEdit(report.id, formattedData);
            onRequestClose();
        } catch (error) {
            console.error('Failed to edit report:', error);
            toast.error(error.message || 'Failed to edit report. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="fixed inset-0 bg-black bg-opacity-30" onClick={onRequestClose}></div>
            <div className="bg-white rounded-lg shadow-lg md:max-w-6xl w-full p-6 relative z-10 max-h-[95vh] overflow-y-auto no-scrollbar">
                <FaTimes className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 cursor-pointer" onClick={onRequestClose} size={12} />
                <h2 className="text-xl font-semibold mb-4">Edit Report</h2>
                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        <div className="flex flex-col mb-4 col-span-2">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Report</label>
                            <textarea
                                name="report"
                                value={formData.report}
                                onChange={handleChange}
                                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                rows="6"
                            />
                        </div>
                        {[
                            'error',
                            'patient_name',
                            'doctor_name',
                            'clinic_name',
                            'row_number',
                            'audio_language',
                            'report_language',
                            'status',
                            'user_id',
                            'comment',
                            'confidence',
                            'template',
                            'meta_type',
                        ].map((field) => (
                            <div className="flex flex-col mb-4" key={field}>
                                <label className="block text-gray-700 text-sm font-bold mb-2">{field.replace(/_/g, ' ')}</label>
                                <input
                                    type="text"
                                    name={field}
                                    value={formData[field]}
                                    onChange={handleChange}
                                    className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                />
                            </div>
                        ))}
                        <div className='flex gap-10'>
                            <div className="flex items-center mb-4 col-span-2 sm:col-span-1">
                                <label className="block text-gray-700 text-sm font-bold mb-2 mr-2">Report Fetched</label>
                                <input
                                    type="checkbox"
                                    name="report_fetched"
                                    checked={formData.report_fetched}
                                    onChange={handleChange}
                                    className="form-checkbox h-5 w-5 text-indigo-500"
                                />
                            </div>
                            <div className="flex items-center mb-4 col-span-2 sm:col-span-1">
                                <label className="block text-gray-700 text-sm font-bold mb-2 mr-2">Uses Custom Template</label>
                                <input
                                    type="checkbox"
                                    name="uses_custom_template"
                                    checked={formData.uses_custom_template}
                                    onChange={handleChange}
                                    className="form-checkbox h-5 w-5 text-indigo-500"
                                />
                            </div>
                        </div>
                        <div className="flex flex-col mb-4 col-span-2 sm:col-span-1">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Nova Transcription</label>
                            <textarea
                                name="nova_transcription"
                                value={formData.nova_transcription}
                                onChange={handleChange}
                                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                rows="3"
                            />
                        </div>
                        <div className="flex flex-col mb-4 col-span-2 sm:col-span-1">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Whisper Transcription</label>
                            <textarea
                                name="whisper_transcription"
                                value={formData.whisper_transcription}
                                onChange={handleChange}
                                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                rows="3"
                            />
                        </div>
                        <div className="flex flex-col mb-4 col-span-2 sm:col-span-1">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Assembly AI Transcription</label>
                            <textarea
                                name="assembly_ai_transcription"
                                value={formData.assembly_ai_transcription}
                                onChange={handleChange}
                                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                rows="3"
                            />
                        </div>
                        <div className="flex flex-col mb-4 col-span-2 sm:col-span-1">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Rev AI Transcription</label>
                            <textarea
                                name="rev_ai_transcription"
                                value={formData.rev_ai_transcription}
                                onChange={handleChange}
                                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                rows="3"
                            />
                        </div>
                        <div className="flex flex-col mb-4 col-span-2 sm:col-span-1">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Created At</label>
                            <input
                                type="datetime-local"
                                name="created_at"
                                value={formData.created_at}
                                onChange={handleChange}
                                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>
                        <div className="flex flex-col mb-4 col-span-2 sm:col-span-1">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Retried At</label>
                            <input
                                type="datetime-local"
                                name="retried_at"
                                value={formData.retried_at}
                                onChange={handleChange}
                                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>
                    </div>
                    <div className="flex justify-end mt-4">
                        <button
                            type="button"
                            onClick={onRequestClose}
                            className="px-4 py-2 border rounded-md shadow-sm focus:outline-none bg-gray-500 text-white mr-2"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={`px-4 py-2 border rounded-md shadow-sm focus:outline-none ${isSaveDisabled ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-blue-500 text-white'}`}
                            disabled={isSaveDisabled}
                        >
                            {isLoading ? 'Saving...' : 'Save Changes'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditReportModal;
