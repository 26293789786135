import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import PasswordInput from './PasswordInput';

const ResetPasswordModal = ({ isOpen, onRequestClose, userEmail, userId, setUserPassword }) => {
  const [email, setEmail] = useState(userEmail || '');
  const [newPassword, setNewPassword] = useState('');
  const [isValidPassword, setIsValidPassword] = useState(false);

  const handleResetPassword = async () => {
    try {
      // Attempt to reset the password using userId
      await setUserPassword(userId, newPassword);
      
      // Close the modal if successful
      onRequestClose();
    } catch (error) {
      // Log the error for debugging
      console.error('Failed to reset password:', error);
      // Optionally show an error message to the user
    }
  };

  const handleClose = () => {
    setEmail(userEmail || ''); // Reset to initial value or empty
    setNewPassword(''); // Clear new password
    onRequestClose(); // Call the provided onRequestClose function
  };

  const isDisabled = !isValidPassword
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
      <div className="fixed inset-0 bg-black bg-opacity-30" onClick={handleClose}></div>
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md mx-auto relative">
        <FaTimes className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 cursor-pointer" onClick={handleClose} size={12} />
        <h2 className="text-xl font-semibold mb-4">Reset Password</h2>
        <label className="mb-2 hidden">
          Email:
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter user's email"
            className="w-full px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
            disabled={!!userEmail} // Disable if userEmail is pre-populated
          />
        </label>
        <label className="flex flex-col gap-2 mb-2">
          <p>New Password:</p>
          <PasswordInput
            value={newPassword} 
            onChange={(e) => setNewPassword(e.target.value)} 
            placeholder='Enter new password'
            setIsValidPassword={setIsValidPassword}
            reset 
          />
        </label>
        <div className="flex justify-end space-x-2 mt-4">
          <button
            onClick={handleClose}
            className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md shadow-sm hover:bg-gray-300 focus:outline-none"
          >
            Cancel
          </button>
          <button
            onClick={isDisabled ? undefined : handleResetPassword}
            className={`px-4 py-2 bg-indigo-600 text-white rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none ${isDisabled && 'opacity-60 cursor-not-allowed'}`}
          >
            Set Password
          </button>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </div>
  );
};

export default ResetPasswordModal;
