import { fetchAuthSession } from 'aws-amplify/auth';
import { toast } from 'react-toastify';

export const trimData = (data) => {
    return Object.keys(data).reduce((acc, key) => {
        acc[key] = typeof data[key] === 'string' ? data[key].trim() : data[key];
        return acc;
    }, {});
};

export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: '2-digit', hour12: false };
    return date.toLocaleString('en-US', options);
};

export const getSignedUrl = async ({ name, type, meta_type }) => {
    try {
        const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      const response = await fetch(`${process.env.REACT_APP_ADMIN_API}/admin/get-signed-url`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken.toString()}`,
            },
            body: JSON.stringify({
                name: name,
                type,
                meta_type: meta_type
            })
        })
        if (!response.ok) {
          toast.error('Error while downloading the file. Please try again')
            throw new Error(`Signed url not found Status: ${response.status}`);
        }
        const data = await response.json()
        return data.url;
    } catch (errorInCatch) {
        console.log("Error: ", errorInCatch);

    }
}

export const handleUpdateStatus = async ({ documentId, status }) => {
  try {
    const tableName = process.env.REACT_APP_TABLE_NAME
    const endPoint = process.env.REACT_APP_ADMIN_API
    const dataToSend = {
      status: status,
      tableName: tableName,
      id: documentId
    }
    const { accessToken } = (await fetchAuthSession()).tokens ?? {};
    let response = await fetch(`${endPoint}/admin/update-status/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken.toString()}`,
      },
      body: JSON.stringify(dataToSend)
    })
    const data = await response.json()
    if (data.statusCode === 200) {
      return data?.id
    }
    return data
  } catch (error) {
    console.log("Error: ", error);
    toast.error('Error while retrying. Please try again');
  }
}