import { create } from 'zustand';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';

const useConstantStore = create((set) => ({
  constants: [],
  fetchConstants: async () => {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      const response = await axios.get(`${process.env.REACT_APP_ADMIN_API}/admin/constant/get-all`,{
        headers: {
          'Authorization': `Bearer ${accessToken.toString()}`,
          'Content-Type': 'application/json'
        },
      });
      set({ constants: response.data.constants });
      console.log("constants ----", response.data.constants);
    } catch (error) {
      console.error('Failed to fetch constants:', error);
    }
  },
  createConstant: async (name, value) => {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/admin/constant/create`, { name, value },{
        headers: {
          'Authorization': `Bearer ${accessToken.toString()}`,
          'Content-Type': 'application/json'
        },
      });
      set((state) => ({ constants: [...state.constants, response.data.constant] }));
      return response.data.constant;
    } catch (error) {
      console.error('Failed to create constant:', error);
      throw error;
    }
  },
  updateConstant: async (id, name, value) => {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      const response = await axios.patch(`${process.env.REACT_APP_ADMIN_API}/admin/constant/update/${id}`, { name, value },{
        headers: {
          'Authorization': `Bearer ${accessToken.toString()}`,
          'Content-Type': 'application/json'
        },
      });
      set((state) => ({
        constants: state.constants.map((constant) =>
          constant.id === id ? response.data.constant : constant
        ),
      }));
      return response.data.constant;
    } catch (error) {
      console.error('Failed to update constant:', error);
      throw new Error(error.response.data.message);
    }
  },
  deleteConstant: async (id) => {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      const response = await axios.delete(`${process.env.REACT_APP_ADMIN_API}/admin/constant/delete/${id}`,{
        headers: {
          'Authorization': `Bearer ${accessToken.toString()}`,
          'Content-Type': 'application/json'
        },
      });
      set((state) => ({
        constants: state.constants.filter((constant) => constant.id !== id),
      }));
      return response.data.message;
    } catch (error) {
      console.error('Failed to delete constant:', error);
      throw new Error(error.response.data.message);
    }
  },
}));

export default useConstantStore;
