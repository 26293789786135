import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';

const CreateConstantModal = ({ isOpen, onRequestClose, onCreate }) => {
    const [name, setName] = useState('');
    const [value, setValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleValueChange = (e) => {
        setValue(e.target.value);
    };

    const handleCancel = () => {
        setName('');
        setValue('');
        onRequestClose();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        await onCreate(name, value);
        setIsLoading(false);
        handleCancel();
    };
    
    const isDisabled = !name || !value;
    
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="fixed inset-0 bg-black bg-opacity-30" onClick={handleCancel}></div>
            <div className="bg-white rounded-lg shadow-lg max-w-xl w-full p-6 relative z-10 max-h-screen overflow-y-auto">
                <FaTimes className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 cursor-pointer" onClick={handleCancel} size={12} />
                <h2 className="text-xl font-semibold mb-4">Create Constant</h2>
                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-1 gap-4">
                        <div className="flex flex-col mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Name</label>
                            <input
                                type="text"
                                value={name}
                                onChange={handleNameChange}
                                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                required
                            />
                        </div>
                        <div className="flex flex-col mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Value</label>
                            <textarea
                                value={value}
                                onChange={handleValueChange}
                                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                rows="4"
                                required
                            />
                        </div>
                    </div>
                    <div className="flex justify-end mt-4">
                        <button
                            type="button"
                            onClick={handleCancel}
                            className="px-4 py-2 border rounded-md shadow-sm focus:outline-none bg-gray-500 text-white mr-2"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={`px-4 py-2 border rounded-md shadow-sm focus:outline-none bg-blue-500 text-white ${isDisabled && 'opacity-80 cursor-not-allowed'}`}
                        >
                            {isLoading ? 'Creating...' : 'Create'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateConstantModal;
