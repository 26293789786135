import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';

const UpdateModal = ({ isOpen, onRequestClose, constant, onUpdate }) => {
  const [name, setName] = useState('');
  const [value, setValue] = useState('');
  const [initialName, setInitialName] = useState('');
  const [initialValue, setInitialValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (constant) {
      setName(constant.name);
      setValue(constant.value);
      setInitialName(constant.name);
      setInitialValue(constant.value);
    }
  }, [constant]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleValueChange = (e) => {
    setValue(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await onUpdate(constant.id, name, value);
    setIsLoading(false);
    onRequestClose();
  };

  const isSaveDisabled = name.trim() === initialName && value.trim() === initialValue;

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black bg-opacity-30" onClick={onRequestClose}></div>
      <div className="bg-white rounded-lg shadow-lg max-w-xl w-full p-6 relative z-10 max-h-screen overflow-y-auto">
        <FaTimes className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 cursor-pointer" onClick={onRequestClose} size={12} />
        <h2 className="text-xl font-semibold mb-4">Update Constant</h2>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 gap-4">
            <div className="flex flex-col mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">Name</label>
              <input
                type="text"
                value={name}
                onChange={handleNameChange}
                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                required
              />
            </div>
            <div className="flex flex-col mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">Value</label>
              <textarea
                value={value}
                onChange={handleValueChange}
                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                rows="4"
                required
              />
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <button
              type="button"
              onClick={onRequestClose}
              className="px-4 py-2 border rounded-md shadow-sm focus:outline-none bg-gray-500 text-white mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`px-4 py-2 border rounded-md shadow-sm focus:outline-none bg-blue-500 text-white ${isSaveDisabled && 'opacity-80 cursor-not-allowed'}`}
              disabled={isSaveDisabled}
            >
              {isLoading ? 'Saving...' : 'Save Changes'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateModal;
