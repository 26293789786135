import React, { useEffect, useMemo, useState } from 'react';
import {
    useReactTable,
    getCoreRowModel,
    getSortedRowModel,
    getFilteredRowModel,
    flexRender,
    createColumnHelper,
} from '@tanstack/react-table';
import { FaPlus, FaPen, FaTrash } from 'react-icons/fa';
import useTemplateStore from '../../store/template-store';
import useUserStore from '../../store/user-store';
import CreateTemplateModal from './create-template-modal';
import UpdateTemplateModal from './update-template-modal';
import DeleteModal from '../../components/DeleteModal.jsx'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../components/Loader';

const columnHelper = createColumnHelper();

const Templates = () => {
    const { templates, fetchTemplates, createTemplate, updateTemplate, deleteTemplate } = useTemplateStore();
    const { users, fetchUsers } = useUserStore();

    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [globalFilter, setGlobalFilter] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            await Promise.all([fetchTemplates(), fetchUsers()]);
            setIsLoading(false);
        };

        fetchData();
    }, [fetchTemplates, fetchUsers]);

    const data = useMemo(() => {
        const usersMap = new Map(users.map(user => [user.user_id, user.name]));

        return templates.map(template => ({
            ...template,
            user_name: usersMap.get(template.user_id) || '',
        })).sort((a, b) => a.user_name.localeCompare(b.user_name));
    }, [templates, users]);


    const columns = useMemo(
        () => [
            columnHelper.accessor('id', {
                header: 'ID',
            }),
            columnHelper.accessor('name', {
                header: 'Name',
            }),
            columnHelper.accessor('value', {
                header: 'Value',
            }),
            columnHelper.accessor('user_name', { 
                header: 'User Name',
            }),
            columnHelper.accessor('user_id', { 
                header: 'User ID',
            }),
            columnHelper.accessor('actions', {
                header: 'Actions',
                cell: ({ row }) => (
                    <div className="flex space-x-4 items-center">
                        <FaPen
                            title="Edit"
                            onClick={() => handleOpenEditModal(row.original)}
                            className="cursor-pointer text-blue-500"
                        />
                        <FaTrash
                            title="Delete"
                            onClick={() => handleOpenDeleteModal(row.original)}
                            className="cursor-pointer text-red-600"
                        />
                    </div>
                ),
            }),
        ],
        []
    );


    const table = useReactTable({
        data,
        columns,
        state: { globalFilter },
        onGlobalFilterChange: setGlobalFilter,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
    });

    const handleOpenCreateModal = () => setIsCreateModalOpen(true);

    const handleOpenEditModal = (template) => {
        setSelectedTemplate(template);
        setIsUpdateModalOpen(true);
    };
    const handleOpenDeleteModal = (template) => {
        setSelectedTemplate(template);
        setIsDeleteModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsCreateModalOpen(false);
        setIsUpdateModalOpen(false);
        setIsDeleteModalOpen(false);
        setSelectedTemplate(null);
    };

    const handleCreateTemplate = async (name, value, user_id) => {
        try {
            await createTemplate(name, value, user_id);
            toast.success('Template created successfully');
            handleCloseModal();
        } catch (error) {
            console.error('Failed to create template:', error);
            toast.error('Failed to create template. Please try again.');
        }
    };

    const handleUpdateTemplate = async (id, templateData) => {
        try {
            await updateTemplate(id, templateData);
            handleCloseModal();
        } catch (error) {
            console.error('Failed to update template:', error);
        }
    };

    const handleDeleteTemplate = async (id) => {
        try {
            await deleteTemplate(id);
            toast.success('Template deleted successfully');
            handleCloseModal();
        } catch (error) {
            console.error('Failed to delete template:', error);
            toast.error('Failed to delete template. Please try again.');
        }
    };

    return (
        <div className="container mx-auto mt-4">
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-semibold">Template List</h1>
                <button
                    className="flex items-center px-4 py-2 bg-black text-white rounded-md shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    onClick={handleOpenCreateModal}
                >
                    <FaPlus className="mr-2" /> Create New
                </button>
            </div>
            <div className="mb-4">
                <input
                    type="text"
                    value={globalFilter}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    placeholder="Search..."
                    className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
            </div>
            <div className="overflow-x-auto shadow-md rounded-lg">
                <table className="min-w-full bg-white border border-gray-300">
                    <thead className="bg-gray-50">
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <th
                                        key={header.id}
                                        className="py-3 px-4 border-b border-gray-200 text-center text-sm font-semibold text-gray-700"
                                    >
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                        <span>
                                            {header.column.getIsSorted()
                                                ? header.column.getIsSortedDesc()
                                                    ? ' 🔽'
                                                    : ' 🔼'
                                                : ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {
                        isLoading ? (
                            <tr><td colSpan={columns.length}><Loader className='flex justify-center items-center h-[60vh]'/></td></tr>
                        ) : 
                            table.getRowModel().rows.length === 0 ? (
                                    <tr><td colSpan={columns.length}><p className='flex justify-center items-center h-[40vh]'>No templates found</p></td></tr>
                        ) :
                        table.getRowModel().rows.map((row) => (
                            <tr key={row.id} className="hover:bg-gray-50">
                                {row.getVisibleCells().map((cell) => (
                                    <td key={cell.id} className="py-3 px-4 border-b border-gray-200 text-sm text-gray-700">
                                        <div className="flex justify-center">{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <CreateTemplateModal
                isOpen={isCreateModalOpen}
                onRequestClose={handleCloseModal}
                onCreate={handleCreateTemplate}
                users={users}
            />
            <UpdateTemplateModal
                isOpen={isUpdateModalOpen}
                onRequestClose={handleCloseModal}
                template={selectedTemplate}
                users={users}
                onUpdate={handleUpdateTemplate}
            />
            <DeleteModal
                isOpen={isDeleteModalOpen}
                onClose={handleCloseModal}
                data={{...selectedTemplate, dataName: 'template'}}
                onDelete={handleDeleteTemplate}
            />
        </div>
    );
};

export default Templates;
