import { create } from 'zustand';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';

const useApiRunStore = create((set) => ({
    apiRun: null,
    apiRuns: [],
    totalApiRuns: 0,
    totalPages: 0,

    fetchApiRuns: async (page = 1, clientId = '') => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.get(`${process.env.REACT_APP_ADMIN_API}/admin/api-runs`, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
                params: { page, clientId },
            });
            console.log('response', response.data);
            
            set({ 
                apiRuns: response.data.apiRuns,
                totalApiRuns: response.data.totalApiRuns,
                totalPages: response.data.totalPages,       
             });
        } catch (error) {
            console.error('Failed to fetch API runs:', error);
        }
    },

    fetchApiRunById: async (id) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.get(`${process.env.REACT_APP_ADMIN_API}/admin/api-runs/${id}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            return response.data.apiRun;
        } catch (error) {
            console.error(`Failed to fetch API run with id ${id}:`, error);
            throw error;
        }
    },

    deleteApiRun: async (id) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.delete(`${process.env.REACT_APP_ADMIN_API}/admin/api-runs/${id}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            set((state) => ({
                apiRuns: state.apiRuns.filter((run) => run.id !== id),
            }));
            return response.data.message;
        } catch (error) {
            console.error('Failed to delete API run:', error);
            throw new Error(error.response.data.message);
        }
    },
}));

export default useApiRunStore;
