// src/Login.js
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import DarkLogo from '../images/logotype.png';
//import UserIcon from '../images/user-icon.png';
import { useNavigate } from 'react-router-dom';
import TextInput from '../components/ui/TextInput';
import Button from '../components/ui/Button';
//import { GradientBorder } from '../components/ui/GradientBorder';
//import axios from 'axios';
import useUserStore from '../store/user-store';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [resetCode, setResetCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false); // State to manage visibility of password reset fields
  const sendOTP = useUserStore((state)=>state.sendOTP);
  const resetPassword = useUserStore((state)=>state.resetPassword);
  
  const navigate = useNavigate();

  const handleSendOTP = async (e) => {
    console.log("I am here")
    e.preventDefault();
    try {
    console.log("I am here 2", email)
      const message = await sendOTP(email);
      toast.success(message);
      setIsCodeSent(true); // Set state to true on successful OTP send
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }
    try {
      const message = await resetPassword(email, resetCode, newPassword);
      toast.success(message);
      navigate('/login'); 
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className='w-full h-screen bg-[#FAFAFA] flex flex-col items-center sm:flex sm:justify-center font-SuisseIntl'>
      <div className='flex flex-col items-center mt-12'>
        <img width={'180px'} src={DarkLogo} alt='logo not found' />
        <p className='text-[#505050] font-SuisseIntlLight font-normal mt-[19.2px] text-[16px]'>
          Admin Portal
        </p>
      </div>
      <div className='w-[90vw] md:w-[440px] max-w-[440px] rounded-2xl card-shadow bg-white shadow-sm p-[30px] mt-10 text-center transition-all ease-in-out duration-150 flex flex-col justify-center items-center'>
        {/* <GradientBorder>
          <div className="shadow-sm bg-white rounded-full px-4 py-4">
            <img width={'24px'} height={'24px'} src={UserIcon} alt='Login circle not found' />
          </div>
        </GradientBorder> */}
        <h1 className='mt-[20px] text-[24px] font-medium'>Reset Password</h1>
        <hr className='h-[0.5px] bg-[#E5E7EC] mt-[20px] w-full' />
        <div className='w-full'>
          {!isCodeSent ? (
            <form className='text-left' onSubmit={handleSendOTP}>
              <div className='mt-[20px]'>
                <TextInput required={true} name={"email"} label={"Email"} placeholder={"Enter your email"} type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div className='mt-[20px]'>
                <Button className='font-medium' variant='default' type='submit'>Send OTP</Button>
              </div>
            </form>
          ) : (
            <form className='text-left mt-[20px]' onSubmit={handleResetPassword}>
              <div className='mt-[20px]'>
                <TextInput required={true} name={"resetCode"} label={"Reset Code"} placeholder={"Enter the reset code"} type='text' value={resetCode} onChange={(e) => setResetCode(e.target.value)} />
              </div>
              <div className='mt-[20px]'>
                <TextInput required={true} name={"newPassword"} label={"New Password"} placeholder={"Enter your new password"} type='password' value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
              </div>
              <div className='mt-[20px]'>
                <TextInput required={true} name={"confirmPassword"} label={"Confirm Password"} placeholder={"Confirm your new password"} type='password' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
              </div>
              <div className='mt-[20px]'>
                <Button className='font-medium' variant='default' type='submit'>Reset Password</Button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
