// src/Users.js
import React from 'react';
// import useUserStore from '../store/user-store';
import UserList from './user-list';

const Users = () => {
    // const user = useUserStore((state) => state.user);

    return (
        <div>
            {/* <h1>Welcome, {user.username}!</h1>
      <p>Your Users will be displayed here.</p> */}
            <UserList />
        </div>
    );
};

export default Users;
