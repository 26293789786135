import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PasswordInput from '../../components/PasswordInput';

const CreateUserModal = ({ isOpen, onRequestClose, onCreate, setUserPassword, organisations }) => {
  const initialFormData = {
    name: '',
    service_name: '',
    words: '',
    max_word_limit_for_report: '',
    email: '',
    password: '',
    docx_template_s3_bucket_name: '',
    docx_template_file_name: '',
    paying_customer: false,
    trial_end_date: '',
    organisation_id: '',
    is_admin: false,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [fieldsFilled, setfieldsFilled] = useState(true);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  useEffect(() => {
    const areRequiredFieldsFilled =
      formData.name.trim() !== '' &&
      formData.service_name.trim() !== '' &&
      formData.words.trim() !== '' &&
      formData.max_word_limit_for_report.trim() !== '' &&
      formData.email.trim() !== '' &&
      formData.password.trim() !== '';

    setfieldsFilled(!areRequiredFieldsFilled);
  }, [formData]);

  const handleCreate = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await onCreate(formData);
      setFormData(initialFormData); // Reset the form to initial state
      toast.success("User Created Successfully");
      onRequestClose();
    } catch (error) {
      console.error('Failed to create user or set password:', error);
      toast.error('Failed to create user or set password. Please try again.')
      //setError('Failed to create user or set password. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setFormData(initialFormData); // Reset the form to initial state
    onRequestClose();
  };

  const isDisabled = fieldsFilled || !isValidPassword;
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black bg-opacity-30" onClick={handleCancel}></div>
      <div className="bg-white rounded-lg shadow-lg max-w-xl w-full p-6 relative z-10 max-h-[88vh] overflow-y-auto no-scrollbar">
        <FaTimes className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 cursor-pointer" onClick={handleCancel} size={12} />
        <h2 className="text-xl font-semibold mb-2">Create User</h2>
        {error && <p className="text-red-600">{error}</p>}
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div className="flex flex-col mb-2">
              <label className="block text-gray-700 text-sm font-bold mb-2">Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                required
              />
            </div>
            <div className="flex flex-col mb-2">
              <label className="block text-gray-700 text-sm font-bold mb-2">Organisation</label>
              <select
                name="organisation_id"
                value={formData.organisation_id}
                onChange={(e) => { setFormData({ ...formData, organisation_id: e.target.value }) }}
                className="px-4 text-sm py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                required
              >
                <option value="">Select</option>
                {organisations.map((org) => (
                  <option key={org.id} value={org.id}>
                    {org.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col mb-2">
              <label className="block text-gray-700 text-sm font-bold mb-2">Words</label>
              <input
                type="text"
                name="words"
                value={formData.words}
                onChange={handleChange}
                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                required
              />
            </div>
            <div className="flex flex-col mb-2">
              <label className="block text-gray-700 text-sm font-bold mb-2">Service Name</label>
              <input
                type="text"
                name="service_name"
                value={formData.service_name}
                onChange={handleChange}
                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                required
              />
            </div>
            <div className="flex flex-col mb-2">
              <label className="block text-gray-700 text-sm font-bold mb-2">Max Word Limit</label>
              <input
                type="text"
                name="max_word_limit_for_report"
                value={formData.max_word_limit_for_report}
                onChange={handleChange}
                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                required
              />
            </div>
            <div className="flex flex-col mb-2">
              <label className="block text-gray-700 text-sm font-bold mb-2">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                required
              />
            </div>
            <div className="flex flex-col mb-2">
              <label className="block text-gray-700 text-sm font-bold mb-2">S3 Bucket Name</label>
              <input
                type="text"
                name="docx_template_s3_bucket_name"
                value={formData.docx_template_s3_bucket_name}
                onChange={handleChange}
                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
            </div>
            <div className="flex flex-col mb-2">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Password
              </label>
              <PasswordInput
                value={formData.password}
                onChange={handleChange}
                placeholder={'Enter password'}
                setIsValidPassword={setIsValidPassword}
              />
            </div>
            <div className="flex flex-col mb-2">
              <label className="block text-gray-700 text-sm font-bold mb-2">File Name</label>
              <input
                type="text"
                name="docx_template_file_name"
                value={formData.docx_template_file_name}
                onChange={handleChange}
                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
            </div>
            <div className="flex flex-col mb-2">
              <label className="block text-gray-700 text-sm font-bold mb-2">Trial End Date</label>
              <input
                type="date"
                name="trial_end_date"
                value={formData.trial_end_date}
                onChange={handleChange}
                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
            </div>
            <div className="flex items-center">
              <label className="block text-gray-700 text-sm font-bold mr-2 mt-2">Paying Customer</label>
              <input
                type="checkbox"
                name="paying_customer"
                checked={formData.paying_customer}
                onChange={handleChange}
                className="form-checkbox h-5 w-5 text-indigo-600 mt-2"
              />
            </div>
            <div className="flex items-center">
              <label className="block text-gray-700 text-sm font-bold mr-2 mt-2">Is Admin</label>
              <input
                type="checkbox"
                name="is_admin"
                checked={formData.is_admin}
                onChange={handleChange}
                className="form-checkbox h-5 w-5 text-indigo-600 mt-2"
              />
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <button
              type="button"
              onClick={handleCancel}
              className="px-4 py-2 border rounded-md shadow-sm focus:outline-none bg-gray-500 text-white mr-2"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={isDisabled ? undefined : handleCreate}
              className={`px-4 py-2 border rounded-md shadow-sm focus:outline-none bg-blue-500 text-white ${isDisabled && 'opacity-60 cursor-not-allowed'}`}
              disabled={isLoading}
            >
              {isLoading ? 'Creating...' : 'Create'}
            </button>
          </div>
        </form>
        {/* <ToastContainer /> */}

      </div>
    </div>
  );
};

export default CreateUserModal;
