import { create } from 'zustand';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';

const useTemplateStore = create((set) => ({
    template: null,
    templates: [],
    fetchTemplates: async () => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.get(`${process.env.REACT_APP_ADMIN_API}/admin/templates`, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            set({ templates: response.data.templates });
        } catch (error) {
            console.error('Failed to fetch templates:', error);
        }
    },
    fetchTemplateById: async (id) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.get(`${process.env.REACT_APP_ADMIN_API}/admin/templates/${id}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            return response.data.template;
        } catch (error) {
            console.error(`Failed to fetch template with id ${id}:`, error);
            throw error;
        }
    },
    createTemplate: async (name, value, user_id) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/admin/templates`, { name, value, user_id }, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            set((state) => ({ templates: [...state.templates, response.data.template] }));
            return response.data.template;
        } catch (error) {
            console.error('Failed to create template:', error);
            throw error;
        }
    },
    updateTemplate: async (id, updatedData) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.patch(`${process.env.REACT_APP_ADMIN_API}/admin/templates/${id}`, updatedData, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            set((state) => ({
                templates: state.templates.map((template) =>
                    template.id === id ? response.data.template : template
                ),
            }));
            console.log("response: ", response.data.template);
            return response.data.template;
        } catch (error) {
            console.error('Failed to update template:', error);
            throw new Error(error.response.data.message);
        }
    },
    deleteTemplate: async (id) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.delete(`${process.env.REACT_APP_ADMIN_API}/admin/templates/${id}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            set((state) => ({
                templates: state.templates.filter((template) => template.id !== id),
            }));
            return response.data.message;
        } catch (error) {
            console.error('Failed to delete template:', error);
            throw new Error(error.response.data.message);
        }
    },
}));

export default useTemplateStore;
