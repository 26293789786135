import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';

const CreateTemplateModal = ({ isOpen, onRequestClose, onCreate, users }) => {
  const initialFormData = {
    name: '',
    value: '',
    user_id: '',
  };

  const usersData = users.sort((a, b) => a.name.localeCompare(b.name));
  const [formData, setFormData] = useState(initialFormData);
  const [isLoading, setIsLoading] = useState(false);

  const handleCreate = async () => {
    setIsLoading(true);
    await onCreate(formData.name, formData.value, formData.user_id);
    setIsLoading(false);
    handleCancel();
  };

  const handleCancel = () => {
    setFormData(initialFormData);
    onRequestClose();
  };

  const isDisabled = !formData.name || !formData.value || !formData.user_id;

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black bg-opacity-30" onClick={handleCancel}></div>
      <div className="bg-white rounded-lg shadow-lg max-w-xl w-full p-6 relative z-10 max-h-screen overflow-y-auto">
        <FaTimes className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 cursor-pointer" onClick={handleCancel} size={12} />
        <h2 className="text-xl font-semibold mb-4">Create Template</h2>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={(e)=>{setFormData({...formData, name: e.target.value})}}
                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                required
              />
            </div>
            <div className="flex flex-col mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">Value</label>
              <textarea
                name="value"
                value={formData.value}
                onChange={(e)=>{setFormData({...formData, value: e.target.value})}}
                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                rows="4"
                required
              />
            </div>
            <div className="flex flex-col mb-4 sm:w-[45%]">
              <label className="block text-gray-700 text-sm font-bold mb-2">User</label>
              <select
                name="user_id"
                value={formData.user_id}
                onChange={(e)=>{setFormData({...formData, user_id: e.target.value})}}
                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                required
              >
                <option value="" disabled>Select User</option>
                {usersData.map((user) => (
                  <option key={user.id} value={user.user_id}>
                    {user.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <button
              type="button"
              onClick={handleCancel}
              className="px-4 py-2 border rounded-md shadow-sm focus:outline-none bg-gray-500 text-white mr-2"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleCreate}
              className={`px-4 py-2 border rounded-md shadow-sm focus:outline-none bg-blue-500 text-white ${isDisabled && 'opacity-80 cursor-not-allowed'}`}
            >
              {isLoading ? 'Creating...' : 'Create'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateTemplateModal;